// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-digital-marketing-js": () => import("/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-ecommerce-js": () => import("/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-index-js": () => import("/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-js": () => import("/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-mobile-apps-js": () => import("/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/src/pages/mobile-apps.js" /* webpackChunkName: "component---src-pages-mobile-apps-js" */)
}

