module.exports = [{
      plugin: require('/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-92897811-2","head":true},
    },{
      plugin: require('/Users/econkle/Projects/Gatsby-Examples/BTCAttorneys/btcattorneys/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
